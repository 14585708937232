:root {
    --light-primary-color: #ffffff;
    --default-primary-color: #cad5db;
    --dark-primary-color: #5f686d;
    --light-primary-contrast: rgba(0, 0, 0, 0.87);
    --default-primary-contrast: #0e171b;
    --dark-primary-contrast: #ffffff;


    --light-accent-color: #ffe282;
    --default-accent-color: #ffcd26;
    --dark-accent-color: #ffc400;
    --light-accent-contrast: rgba(0, 0, 0, 0.87);
    --default-accent-contrast: #0e171b;
    --dark-accent-contrast: #ffffff;
}
