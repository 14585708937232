@use "sass:map";
@use '@angular/material' as mat;
@use './color-vars.css';

$light-highlight-text: #0e171b;

$score-low: #729f56;
$score-medium: #d38248;
$score-high: #ff735a;

$high-priority: #fb87c6;
$medium-priority: #ae90c2;
$low-priority: #93b8bf;

$app-background: #181818;
$bg-surface-1: #151515;
$bg-surface-2: #202020;
$bg-surface-3: #303030;
$bg-surface-4: #535353;
$bg-surface-5: rgba(white, 0.15);
$row-hover: #272727;

$side-sell: #ff735a;
$side-buy: #badda8;

$app-hyperlink: #2a5279;
$tooltip-icon-hover: #1465B4;
$tooltip-icon-pressed: #2F88DF;

$disabled-color: rgba(white, 0.5);
$disabled-bg: rgba(white, 0.2);
$disabled-fill: #757575;

$snackbar-info-bg: #c1e7ee;

$modified-param: #c1e7ee;
$invalid-param: #FF735A;


$chart-green: #67C082;
$chart-light-blue: #84ddff;
$chart-blue: #44ADD5;
$chart-dark-blue: #357289;

$panels-hover: #2E3335;

$tooltip-bd-color: #7FAEE0;


$solidus-primary: (
    100: #ffffff,
    500:#cad5db,
    700: #5f686d,
    contrast: (
        100: rgba(black, 0.87),
        500: $light-highlight-text,
        700: white
    )
);

$solidus-accent: (
    100: #ffe282,
    200: #ffe282,
    300: #ffcd26,
    500: #ffcd26,
    600: #ffcd268a,
    700: #ffc400,
    contrast: (
        100: rgba(black, 0.87),
        500: #0e171b,
        700: white
    )
);

$solidus-warn: (
    A200: #f4967f,
    A400: #f17059,
    A700: #fddeb5,
    contrast: (
        A200: $light-highlight-text,
        A400: $light-highlight-text,
        A700: $light-highlight-text
    )
);
