@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables.scss';
@import 'src/theme/mixins/mixins';
@import 'src/theme/solidus-black/typography';

// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ag-grid-table-theme($theme, $typography) {
    // Extract the palettes you need from the theme definition.
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);


    body {
        /* override value of backgroundColor, foregroundColor and spacing parameters */
        --ag-grid-size: 5.5px; /* very compact */
        --ag-header-height: 32px;
        --ag-cell-horizontal-padding: 7px;
        --ag-spacing: 7px;

        /* use dark scrollbars */
        --ag-browser-color-scheme: dark;
        --ag-header-text-color: var(--default-primary-color);
        --ag-header-background-color: colors.$bg-surface-1;
        --ag-accent-color: var(--default-accent-color);
        --ag-header-font-family: mat.m2-font-family($typography, body-1);
        --ag-font-family: mat.m2-font-family($typography, body-1);
        --ag-border-color: #ffffff26;
        --ag-wrapper-border-radius: 0;

        --ag-checkbox-border-width: 2px;
        --ag-checkbox-border-radius: 2px;
        --ag-focus-shadow: none;

        --ag-row-hover-color: rgba(39, 39, 39);
        --ag-selected-row-background-color: rgba(33, 150, 243, .3);
        --ag-side-button-selected-underline-color: var(--default-accent-color);

        --ag-checkbox-unchecked-background-color: colors.$bg-surface-1;

        --ag-input-background-color: colors.$bg-surface-1;
    }

    ag-grid-angular {
        .ag-side-buttons {
            background: colors.$bg-surface-1;
            padding-top: $five-quarter-gaps;

            .ag-side-button {
                &.ag-selected {
                    border-color: transparent;
                }

                button {
                    padding-inline: $one-third-gap;
                }
            }
        }

        .ag-side-bar  {
            background: colors.$bg-surface-3;
        }

        .ag-header-icon {
            background: none;
            outline: none;

            &:hover {
                background: none;
                outline: none;
                box-shadow: none;
                color: var(--default-accent-color);
            }
        }

        .ag-filter-active {
            background: none;
            outline: none;
            color: var(--default-accent-color);

            .ag-icon-filter {
                clip-path: unset;
            }

            &::after {
                display: none;
            }
        }

        .ag-row-loading {
            display: none;
        }
    }

    .ag-grid-table {
        .ag-sort-indicator-icon .ag-icon {
            color: mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-header-cell-filtered .ag-filter-icon {
            display: flex;
            color: mat.m2-get-color-from-palette($accent, 500);
        }

        .ag-header-cell:not(.ag-header-cell-filtered) .ag-filter-icon {
            display: none;
        }

        .ag-table-header {
            background-color: colors.$bg-surface-1;
            border: 1px solid colors.$bg-surface-5;
            border-bottom-color: none;

            label {
                color: mat.m2-get-color-from-palette($foreground, secondary-text);
            }

            .export-csv-btn {
                color: mat.m2-get-color-from-palette(map-get($tardis-theme, primary), 500);
            }
        }

        .ag-filter {
            .ag-picker-field-wrapper {
                background: transparent;
            }
        }

        .ag-body-viewport.ag-layout-auto-height {
            min-height: 270px;

            .ag-center-cols-viewport {
                min-height: 270px;
            }
        }

        .ag-cell.side-highlight {
            padding-left: $gap;
            width: 50px;

            &:before {
                content: '';
                width: 3px;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
            }

            &.status-low:before {
                background: colors.$score-low;
            }

            &.status-medium:before {
                background: colors.$score-medium;
            }

            &.status-high:before {
                background: colors.$score-high;
            }

            &.status-completed:before {
                background: colors.$score-low;
            }

            &.status-failed:before {
                background: colors.$score-high;
            }

            &.status-pending:before {
                background: colors.$low-priority;
            }

            &.status-running:before {
                background: colors.$chart-blue;
            }

            &.status-partial_success:before {
                background: colors.$side-buy;
            }
        }

        .ag-labeled.ag-checkbox {
            margin-left: $two-third-gap;
        }
    }
}
