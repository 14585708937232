// Define a mixin that accepts a theme and outputs the theme-specific styles.
@mixin ag-grid-tm-alerts-table-theme($theme, $typography) {
    $background: map-get($theme, background);

    .ag-grid-tm-alerts-table {
        ag-grid-angular .ag-sort-order {
            display: none;
        }

        .ag-side-bar-right .ag-tool-panel-wrapper {
            background-color: colors.$bg-surface-3;
            border-right: none;
            width: 175px;
        }

        .ag-ltr .ag-cell-focus:not(.ag-cell-range-selected) {
            border: none;
        }
    }
}
