@use '@angular/material' as mat;
@use 'src/theme/solidus-black/colors' as colors;
@import 'src/theme/variables';

@mixin model-test-analytics-stats-table-theme($theme) {
    // Extract the palettes you need from the theme definition.
    $foreground: map-get($theme, foreground);
    $accent: map-get($theme, accent);

    .trds-model-test-analytics-stats-table {
        .wrapper {
            border: 1px solid colors.$bg-surface-3;
            width: 100%;
            max-height: 500px;
            overflow: auto;
            position: relative;
        }
        table {
            border-spacing: 0;
            white-space: nowrap;
            table-layout: fixed;
        }

        thead,
        tr>th {
            position: sticky;
            background: colors.$app-background;
        }

        thead {
            top: 0;
            z-index: 2;
        }
        tr>th {
            left: 0;
            z-index: 1;
        }
        thead tr>th:first-child {
            z-index: 3;
        }

        th,
        td {
            text-align: left;
            padding: 10px;
        }
        td {
            background: colors.$bg-surface-2;
            border-bottom: 4px solid colors.$app-background;
        }
        tr>*:last-child {
            border-right: 0;
        }

        .algo-param-cell {
            background: #2E373B;
        }

        .highlight {
            border-left: 2px solid mat.m2-get-color-from-palette($accent, 500);
            border-right: 2px solid mat.m2-get-color-from-palette($accent, 500);
        }

        .highlight-top {
            border-top: 2px solid mat.m2-get-color-from-palette($accent, 500);
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
        }

        .highlight-bottom {
            border-bottom: 2px solid mat.m2-get-color-from-palette($accent, 500);
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
}
